import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Info from '@material-ui/icons/Info';

import dirVal from '../../data/validation';
import styles from '../../theme';

class DirectorInfo extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      firstNameError: "",
      lastName: "",
      lastNameError: "",
      school: "",
      schoolError: "",
      cell: "",
      cellError: "",
      work: "",
      workError: "",
      email: "",
      emailError: "",
      vemail: "",
      vemailError: "",
      nafme: "",
      nafmeError: "",
      password: "",
      passwordError: "",
      vpassword: "",
      vpasswordError: "",
      infoError: "",
      loading: false,
      redirect: "",
      schools: []
    }
    this.submitInfo = this.submitInfo.bind(this);
    this.hasError = this.hasError.bind(this);
  }

  hasError(value) {
    if (this.state[`${value}Error`].length > 0) return true;
    return false;
  }

  validateForm() {
    let valid = true;
    const update = {};
    if (!dirVal.validName(this.state.firstName)) {
      valid = false;
      update.firstNameError = "Enter a first name";
    } else { update.firstNameError = ""; }
    if (!dirVal.validName(this.state.lastName)) {
      valid = false;
      update.lastNameError = "Enter a last name";
    } else { update.lastNameError = ""; }
    if (!dirVal.validSchool(this.state.school)) {
      valid = false;
      update.schoolError = "School must be selected";
    } else { update.schoolError = ""; }
    if (!dirVal.validPhone(this.state.cell)) {
      valid = false;
      update.cellError = "Enter a valid phone number";
    } else { update.cellError = ""; }
    if (!dirVal.validPhone(this.state.work)) {
      valid = false;
      update.workError = "Enter a valid phone number";
    } else { update.workError = ""; }
    if (!dirVal.validEmail(this.state.email)) {
      valid = false;
      update.emailError = "Enter a valid email address";
    } else { update.emailError = ""; }
    if (!dirVal.validEmail(this.state.vemail) || this.state.vemail !== this.state.email) {
      valid = false;
      update.vemailError = "Please validate the email address";
    } else { update.vemailError = ""; }

    if (!(this.state.password.length >= 8)) {
      valid = false;
      update.passwordError = "Passwords must be at least 8 characters";
    } else { update.passwordError = ""; }
    if (!(this.state.vpassword.length >= 8) || this.state.vpassword !== this.state.password) {
      valid = false;
      update.vpasswordError = "Passwords must match";
    } else { update.vpasswordError = ""; }

    if (!dirVal.validNafme(this.state.nafme)) {
      valid = false;
      update.nafmeError = "Enter a valid NAfME number";
    } else { update.nafmeError = ""; }
    this.setState(update);
    return valid;
  }

  submitInfo() {
    const valid = this.validateForm();
    if (valid) {
      this.setState({
        loading: true
      });
      this.postData("https://api.coloradoaso.org/director", {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        school: this.state.school,
        cell: this.state.cell.replace(/[^0-9]/g, ''),
        work: this.state.work.replace(/[^0-9]/g, ''),
        email: this.state.email,
        nafme: this.state.nafme,
        password: this.state.password
      }).then(res => {

        if (res.errors) {
          if (res.errors === "email");
          this.setState({
            infoError: "This email is already taken. Please contact coloradoaso@gmail.com",
            emailError: "Email taken",
            loading: false
          });
        } else {
          this.setState({
            redirect: res._id
          })
        }
      }).catch(err => {
        this.setState({
          infoError: "There was an error. Please try again later."
        });
      });
    }
  }

  postData(url, data) {
    // Default options are marked with *
    return fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // "Content-Type": "application/x-www-form-urlencoded",
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
      .then(response => response.json()); // parses response to JSON
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value }, () => {
      if (this.state[`${name}Error`].length > 0) {
        this.validateForm();
      }
    });
  };

  componentDidMount() {
    fetch("https://api.coloradoaso.org/schools", {
      method: 'get'
    })
      .then(res => res.json())
      .then(
        (result) => {
          result.unshift("");
          this.setState({
            loading: false,
            schools: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            loading: false,
            infoError: error.message
          });
        }
      )
  }

  render() {
    const { classes } = this.props;

    if (this.state.redirect.length > 0) {
      return <Redirect to={`/director-registration/success/${this.state.redirect}`} />
    }

    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Director Information
        </Typography>
        <Typography variant="body2" gutterBottom>
          {this.state.infoError}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              value={this.state.firstName}
              onChange={this.handleChange('firstName')}
              helperText={this.state.firstNameError}
              error={this.hasError('firstName')}
              id="firstName"
              name="firstName"
              label="First name"
              fullWidth
              autoComplete="fname"
              disabled={this.state.loading}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              value={this.state.lastName}
              onChange={this.handleChange('lastName')}
              helperText={this.state.lastNameError}
              error={this.hasError('lastName')}
              id="lastName"
              name="lastName"
              label="Last name"
              fullWidth
              autoComplete="lname"
              disabled={this.state.loading}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              value={this.state.email}
              onChange={this.handleChange('email')}
              helperText={this.state.emailError}
              error={this.hasError('email')}
              id="email"
              name="email"
              label="Email Address"
              fullWidth
              autoComplete="email"
              disabled={this.state.loading}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              value={this.state.vemail}
              onChange={this.handleChange('vemail')}
              helperText={this.state.vemailError}
              error={this.hasError('vemail')}
              id="vemail"
              name="vemail"
              label="Verify Email Address"
              fullWidth
              autoComplete="verify email"
              disabled={this.state.loading}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              value={this.state.cell}
              onChange={this.handleChange('cell')}
              helperText={this.state.cellError}
              error={this.hasError('cell')}
              id="mobile"
              name="mobile"
              label="Cell Number"
              fullWidth
              autoComplete="mobile"
              disabled={this.state.loading}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              value={this.state.work}
              onChange={this.handleChange('work')}
              helperText={this.state.workError}
              error={this.hasError('work')}
              id="work"
              name="work"
              label="Office Number"
              fullWidth
              autoComplete="work"
              disabled={this.state.loading}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              value={this.state.nafme}
              onChange={this.handleChange('nafme')}
              helperText={this.state.nafmeError ? this.state.nafmeError : "Enter without leading zeros"}
              error={this.hasError('nafme')}
              id="nafme"
              name="nafme"
              label="NAfME Number"
              fullWidth
              autoComplete="nafme"
              disabled={this.state.loading}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth error={this.hasError('school')}>
              <InputLabel required htmlFor="school">School</InputLabel>
              <Select
                value={this.state.school}
                onChange={this.handleChange('school')}
                inputProps={{
                  name: 'school',
                  id: 'school',
                }}
                native
                required
                name="school"
                disabled={this.state.loading}
              >
                {this.state.schools.map(s => <option key={s} value={s}>{s}</option>)}
              </Select>
              <FormHelperText>{this.state.schoolError}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" alignItems="center" wrap="nowrap" spacing={2}>
              <Grid item>
                <Info />
              </Grid>
              <Grid item xs>
                <Typography>If your school is not included in this list please contact ColoradoASO@gmail.com to verify CHSAA eligibility.</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" alignItems="center" wrap="nowrap" spacing={2}>
              <Grid item>
                <Info />
              </Grid>
              <Grid item xs>
                <Typography>If you're unsure of your NAfME Number, please call 1-800-336-3768 or email memberservices@nafme.org to find out.</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              value={this.state.password}
              onChange={this.handleChange('password')}
              helperText={this.state.passwordError}
              error={this.hasError('password')}
              id="password"
              name="password"
              label="Password"
              fullWidth
              autoComplete="password"
              type="password"
              disabled={this.state.loading}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              value={this.state.vpassword}
              onChange={this.handleChange('vpassword')}
              helperText={this.state.vpasswordError}
              error={this.hasError('vpassword')}
              id="vpassword"
              name="vpassword"
              label="Verify Password"
              fullWidth
              autoComplete="verify password"
              type="password"
              disabled={this.state.loading}
            />
          </Grid>

        </Grid>
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="primary"
            onClick={this.submitInfo}
            className={classes.button}
            disabled={this.state.loading}
          >
            {this.state.loading ? "..." : "Submit"}
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

DirectorInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DirectorInfo);